
import { computed, defineComponent, reactive, Ref, ref, UnwrapRef } from 'vue';
import { EditOutlined,PlusCircleOutlined,DeleteOutlined } from '@ant-design/icons-vue';
import deepClone from '@/utils/deepClone'

interface DataItem {
  key: string;
  approvalLevel: string;
  approvalRole: string;
  user: string;
  levelTimes: number;
}

export default defineComponent({
  name:'customApprovalChainTableSecond',
  components: {
    EditOutlined,
    PlusCircleOutlined,
    DeleteOutlined
  },
  setup() {
    const columns = [
      {
        dataIndex: 'approvalLevel',
        slots: { title: 'customTitle', customRender: 'approvalLevel' },
      },
      {
        title: 'ApprovalRole',
        dataIndex: 'approvalRole',
        width: '30%',
        slots: { customRender: 'approvalRole' },
      },
      {
        title: 'User',
        dataIndex: 'user',
        width: '30%',
        slots: { customRender: 'user' },
      },
      {
        title: 'operation',
        dataIndex: 'operation',
        slots: { customRender: 'operation' },
      },
    ];
    const dataSource: Ref<DataItem[]> = ref([
      {
        key: '0',
        approvalLevel: 'Level1',
        approvalRole: 'BU Central E4',
        user: 'Lifang',
        levelTimes: 1
      },
      {
        key: '1',
        approvalLevel: 'Level2',
        approvalRole: 'Controlling E4',
        user: 'Li,Hao',
        levelTimes: 1
      },
    ]);
    const count = computed(() => dataSource.value.length + 1);
    const editableData: UnwrapRef<Record<string, DataItem>> = reactive({});
    const mode = ref('detail')
    const levelTimes = ref<number>(1)
    const editableFlag = ref<boolean>(false)
    const entity = undefined
    const edit = (key: string) => {
      editableData[key] = deepClone(dataSource.value.filter(item => key === item.key)[0]);
    };
    const doEdit = () => {
      editableFlag.value = true
      mode.value = 'save'
    }
    const save = (key: string) => {
      Object.assign(dataSource.value.filter(item => key === item.key)[0], editableData[key]);
      delete editableData[key];
    };
    const onDelete = (record: any) => {
      //dataSource.value = dataSource.value.filter(item => item.key !== key);
      dataSource.value = dataSource.value.map((item) => {
        if(item.key == record.key){
          if(item.levelTimes>=2){
            item.levelTimes--
            return item
          }
          else{
            return item
          }
        }
        else {
          return item
        }
      })
    };
    const handleAdd = () => {
      const newData = {
        key: `${count.value}`,
        approvalLevel: `Level ${count.value}`,
        approvalRole: '',
        user: `London, Park Lane no. ${count.value}`,
        levelTimes: 1
      };
      dataSource.value.push(newData);
    };
    const handleAddRoleAndUser = (record: any) => {
      console.log('record====',record)
      record.levelTimes++
    }
    const entityOptions = ref<string[]>(['Controling B4', 'BU Central', 'CFO', 'COO']);
    const tabVisable = ref<boolean>(false)
    const ntimes = ref<number>(0)
    return {
      columns,
      onDelete,
      handleAdd,
      handleAddRoleAndUser,
      dataSource,
      editableData,
      editableFlag,
      entity,
      entityOptions,
      levelTimes,
      count,
      doEdit,
      edit,
      save,
      mode,
      tabVisable,
      ntimes,
    };
  },
});
